import './Skeleton.scss';

const Skeleton = () => {
  return (
    <div className='skeleton-lines'>
      {[...Array(39)].map((_, index) => (
        <div key={index} className='skeleton-line' />
      ))}
    </div>
  );
};

export default Skeleton;
