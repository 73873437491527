import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './NotFoundPages.scss';

const NotFoundPages = () => {
  const navigate = useNavigate();

  let { pathname } = useLocation();
  if (pathname.startsWith('/')) pathname = pathname.substring(1);
  if (pathname.endsWith('/'))
    pathname = pathname.substring(0, pathname.length - 1);

  return (
    <section className='page-error'>

      <Helmet>
        <title>{`Ошибка 404`}</title>
        <meta name="description" content={`Ошибка 404 на сервисе`} />
        <meta name="robots" content="noindex" />
      </Helmet>

      <h1 className='page-error__title'>404</h1>
      <p className='page-error__subtitle'>
        — в переводе на человеческий это означает, что&nbsp;страница не найдена:
        ее или уже нет, или никогда не было, или в ссылке
        опечатка&nbsp;&#128524;
      </p>
      <button className='page-error__button' onClick={() => navigate('/')}>
        На главную
      </button>
    </section>
  );
};

export default NotFoundPages;
