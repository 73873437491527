import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';
import { FooterLink } from './FooterLink/FooterLink';

import support from 'assets/images/tg.svg';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container __inner'>
        <div className='footer__container-left'>
          <div className='footer__container-left-description'>
            <p className='footer__container-left-title'>
              Крутой бот, не так ли?
            </p>
            <p className='footer__container-left-text'>
              Поделитесь находкой с друзьями и коллегами. <br /> Наша команда
              будет очень благодарна!
            </p>
          </div>
        </div>

        <div className='footer__container-right'>
          <ButtonWithIcon
            title='Отзывчивая техническая поддержка'
            className='footer__container-right-support'
            icon={support}
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            ariaLabel='Перейти в техподдержку'
          />
          <nav className='footer__container-left-nav'>
            <FooterLink text='Договор оферты' to='/docs/offer.pdf' />
            <FooterLink text='Условия оказания услуг' to='/docs/rules.pdf' />
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
