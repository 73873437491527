import { observer } from 'mobx-react';

import './StarRating.scss';

const StarRating = observer(({ value, onChange }) => {
  return (
    <div className='stars-rating'>
      {[1, 2, 3, 4, 5].map((star) => (
        <div
          key={star}
          className={`stars-rating__item ${star <= value ? 'active' : ''}`}
          onClick={() => onChange(star)}
        />
      ))}
    </div>
  );
});

export default StarRating;
