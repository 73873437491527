function getCount(count, var1, var2, var3) {
  let countStr = "";
  if (count % 10 === 1 && count % 100 !== 11) countStr = " " + var1;
  else if (
    [2, 3, 4].includes(count % 10) &&
    ![12, 13, 14].includes(count % 100)
  )
    countStr = " " + var2;
  else countStr = " " + var3;
  return countStr;
}

export function getCountReviews(count) {
  return getCount(count, "отзыв", "отзыва", "отзывов");
}

export function getCountDays(count) {
  return getCount(count, "день", "дня", "дней");
}

export function getCountActions(count) {
  return getCount(count, "действие", "действия", "действий");
}