import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';

import LottieAnimation from 'components/LottieAnimation/LottieAnimation';

import { appStore } from 'AppStore';
import rocketAnimation from 'assets/animation/rocket-animation.json';
import useCountdownTimer from 'utils/hooks/use-countdown-timer';
import { useMatchMedia } from 'utils/hooks/use-match-media';

import './Popup.scss';

export const Popup = observer(() => {
  const { timerCount, resetTimer } = useCountdownTimer(2, appStore.isOpen);
  const { isMobile } = useMatchMedia();

  const handleClose = useCallback(() => {
    appStore.close();
    resetTimer();
  }, [resetTimer]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) handleClose();
    };

    const handleClickOverlay = (e) => {
      if (e.target.classList.contains('popup')) handleClose();
    };

    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClickOverlay);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClickOverlay);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerCount === 0 && appStore.isOpen && appStore.redirectUrl) {
      if (isMobile) {
        window.location.href = appStore.redirectUrl;
      } else {
        const newWindow = window.open(appStore.redirectUrl, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          window.location.href = appStore.redirectUrl;
        }
      }
      handleClose();
    }
  }, [timerCount, handleClose, isMobile]);

  return (
    <div className={`popup ${appStore.isOpen ? 'popup_opened' : ''}`}>
      <div className='popup__plate'>
        <div className='popup__container'>
          <LottieAnimation animationData={rocketAnimation} />
          <div className='popup__header'>
            <p className='popup__header-title'>Переводим на Boostclick!</p>
            <p className='popup__header-description'>Мы перенаправляем вас на биржу задач Boostclick — там исполнители займутся размещением ваших отзывов</p>
          </div>
        </div>
        <div className='popup__buttons'>
          <button className='popup__button stay' onClick={handleClose}>Остаться на странице</button>
          <button className='popup__button go'>Переход через <span>{timerCount} сек</span></button>
        </div>
      </div>
    </div>
  );
});
