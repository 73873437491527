import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';

import Header from 'Header/Header';

import YandexPage from 'pages/Markets/YandexPage/YandexPage';
import AvitoPage from 'pages/Markets/AvitoPage/AvitoPage';
import NotFoundPages from 'pages/NotFoundPages/NotFoundPages';

import Footer from 'Footer/Footer';

import { Popup } from 'Popup/Popup';
import { appStore } from 'AppStore';

import './App.scss';

const App = observer(() => {
  return (
    <>
      <Header store={appStore} />
      <Routes>
        <Route path='/' element={<YandexPage store={appStore} />} />
        <Route path='/ai-otzyv-obyavleniya' element={<AvitoPage store={appStore} />} />
        <Route path='*' element={<NotFoundPages />} />
      </Routes>
      <Popup />
      <Footer />
    </>
  );
});

export default App;
