import { useState, useEffect, useCallback } from 'react';

const useCountdownTimer = (initialTime, isActive) => {
  const [timerCount, setTimerCount] = useState(initialTime);

  const resetTimer = useCallback(() => {
    setTimerCount(initialTime);
  }, [initialTime]);

  useEffect(() => {
    let interval;
    if (isActive) {
      resetTimer();
      interval = setInterval(() => {
        setTimerCount((prevCount) => prevCount > 0 ? prevCount - 1 : 0);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, resetTimer]);

  return { timerCount, resetTimer };
};

export default useCountdownTimer;