import { Link } from 'react-router-dom';

import './FooterLink.scss';

export const FooterLink = ({ to, text }) => {
  return (
    <Link className='footer-link' to={to} target='_blank'>
      {text}
    </Link>
  );
};
