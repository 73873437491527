import { useEffect, useState } from 'react';

import LottieAnimation from 'components/LottieAnimation/LottieAnimation';
import robotAnimation from 'assets/animation/robot-animation.json';

import './LoadingContent.scss';

const LoadingContent = () => {
  const [loadingText, setLoadingText] = useState('Суммирую требования...');

  useEffect(() => {
    const texts = [
      'Суммирую требования...',
      'Повторяю человеческие эмоции...',
      'Подбиваю по SEO...',
      'Обманываю антиплагиат...',
    ];
    let index = 0;
    const interval = setInterval(() => {
      setLoadingText(texts[index]);
      index = (index + 1) % texts.length;
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='loading-content'>
      <LottieAnimation animationData={robotAnimation} />
      <p className='loading-content__text'>{loadingText}</p>
    </div>
  );
};

export default LoadingContent;
