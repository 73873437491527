import { useState } from 'react';

import './Faqed.scss';

const Faqed = ({ children, text }) => {
  const [isActive, setIsActive] = useState(false);

  const defaultPlateStyle = {
    position: 'absolute',
    padding: '8px',
    top: 'calc(100% + 6px)',
    left: '0',
    width: '185px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    transition: 'all 0.3s',
    opacity: 0,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
  };

  const plateStyle = {
    ...defaultPlateStyle,
    opacity: isActive ? 1 : 0,
  };

  const handleMouseEnter = () => {
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
  };

  return (
    <div
      className='faqed'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='faqed__children'>{children}</div>
      <div style={plateStyle}>
        <p className='faqed__text'>{text}</p>
      </div>
      <div className='faqed__icon' />
    </div>
  );
};

export default Faqed;
