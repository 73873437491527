import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Main from 'pages/Main/Main';

import './AvitoPage.scss';

const AvitoPage = observer(({ store }) => {
  return (
    <>
      <Helmet>
        <title>Создание отзывов для досок объявлений. Генерация через нейросеть GPT4 - бесплатно</title>
        <meta name='description' content='Создавайте отзывы бесплатно с помощью нейросети GPT-4. Наш сервис предлагает простой способ генерации отзывов для вашего бизнеса или продукта. Попробуйте сейчас!' />
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <Main store={store} />
    </>
  );
});

export default AvitoPage;
