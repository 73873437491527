import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';
import LottieAnimation from 'components/LottieAnimation/LottieAnimation';

import catAnimation from 'assets/animation/cat-animation.json';
import dice from 'assets/images/diceBlack.svg';

import './InformativeContent.scss';

const InformativeContent = ({ randomFill }) => {
  return (
    <div className='info-content'>
      <div className='info-content__header'>
        <LottieAnimation animationData={catAnimation} />
        <p>Роботы тоже боятся белого листа</p>
      </div>
      <ul className='info-content__list'>
        Робот сочинит отзыв и превратит пустое поле в отзыв. Но не без вашей помощи:
        <li>
          Укажите товар или услугу, имя специалиста или организацию, на которую
          будет отзыв
        </li>
        <li>Выберите, пол AI Автора отзыва</li>
        <li>
          Задайте количество звезд, настроение отзыва, характеристики, которые
          нужно указать
        </li>
        <li>Добавьте комментарий для более специфического запроса</li>
      </ul>
      <div className='info-content__buttons'>
        <ButtonWithIcon
          title='Случайное автозаполнение'
          className='info-content__random-button'
          icon={dice}
          onClick={randomFill}
          ariaLabel='Cгенерировать отзыв с рандомными параметрами'
        />
        <p>По клику поля заполнятся сами собой, <br /> результат будет случайным</p>
      </div>
    </div>
  );
};

export default InformativeContent;
