import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import img1 from 'assets/images/seo-icon/1-1.png';
import img2 from 'assets/images/seo-icon/1-2.png';
import img3 from 'assets/images/seo-icon/1-3.png';
import img4 from 'assets/images/seo-icon/2-1.png';
import img5 from 'assets/images/seo-icon/2-2.png';
import img6 from 'assets/images/seo-icon/2-3.png';

import './SeoContent.scss';

const SeoContent = observer(({ store, isMobile }) => {
  const fullText = [
    'Отзывы играют важную роль в формировании доверия к продуктам и услугам. Положительные отзывы привлекают клиентов, повышают рейтинг и увеличивают продажи. Но создание качественных отзывов может быть долгим и сложным процессом.',
    <br />,
    <br />,
    'Решение? Генерация отзывов с помощью GPT-4!',
    <br />,
    <br />,
    'Наш сервис позволяет вам легко и быстро создавать убедительные и правдоподобные отзывы всего за несколько секунд. Как это работает?',
    <br />,
    <br />,
    <ul>
      <li>
        Выберите оценку: Оцените товар или услугу по шкале от 1 до 5 звезд.
      </li>
      <li>
        Выразите эмоции: Выберите эмодзи, которые отражают ваши чувства после
        использования продукта или услуги.{' '}
      </li>
      <li>
        Получите уникальный отзыв: Наш AI-генератор на базе GPT-4 создаст
        уникальный отзыв, учитывая ваши оценки, эмоции и контекст.
      </li>
    </ul>,
    <br />,
    'Преимущества использования нашего сервиса:',
    <br />,
    <br />,
    <ul>
      <li>
        Экономия времени: Забудьте о долгих часах, потраченных на написание
        отзывов!
      </li>
      <li>
        Правдоподобность: Отзывы, созданные AI, звучат естественно и
        убедительно, как будто их написал реальный человек.{' '}
      </li>
      <li>
        Уникальность: Каждый отзыв индивидуален и адаптируется к вашим
        конкретным потребностям.
      </li>
      <li>
        Бесплатно: Воспользуйтесь нашим сервисом генерации отзывов абсолютно
        бесплатно!
      </li>
    </ul>,
    <br />,
    'Повысьте свою онлайн-репутацию:',
    <br />,
    <br />,
    <ul>
      <li>
        Привлеките новых клиентов: Положительные отзывы увеличивают доверие к
        вашей компании и побуждают людей совершать покупки.{' '}
      </li>
      <li>
        Улучшите свой рейтинг: Высокий рейтинг делает вас более заметными в
        поиске и привлекает больше клиентов.
      </li>
      <li>
        Ответьте на негативные отзывы: Генерируйте положительные отзывы, чтобы
        нейтрализовать негативные и сгладить репутацию.
      </li>
    </ul>,
  ];

  const previewText =
    'Отзывы играют важную роль в формировании доверия к продуктам и услугам. Положительные отзывы привлекают клиентов, повышают рейтинг и увеличивают продажи. Но создание качественных отзывов может быть долгим и сложным процессом. ...';

  return (
    <>
      <section className='seo-content'>
        <div className='seo-content__grid __inner'>
          <div className='seo-content__grid-large'>
            <div className='seo-content__grid-large__container'>
              <div className='seo-content__grid-large__content'>
                <div className='seo-content__grid-large__content-wrapper'>
                  <div className='seo-content__grid-large__content-icons'>
                    <div className='seo-content__grid-large__content-icons-yandex' />
                    <div className='seo-content__grid-large__content-icons-gis' />
                  </div>
                  <div className='seo-content__grid-large__content-description'>
                    <p className='seo-content__grid-large__content-description-title'>
                      Делись <br /> впечатлениями
                    </p>
                    <p className='seo-content__grid-large__content-description-text'>
                      GPT напишет отзыв за тебя!
                    </p>
                  </div>
                </div>

                <div className='seo-content__grid-large__content-qr'>
                  <p className='seo-content__grid-large__content-qr-text'>
                    Пару секунд — и отзыв готов! <br /><span>Сканируй QR-код</span>
                  </p>
                  <div className='seo-content__grid-large__content-qr-icon' />
                </div>
              </div>
            </div>
          </div>
          <div className='seo-content__grid-container'>
            <img src={img1} alt='' className='grid-item' />
            <img src={img2} alt='' className='grid-item' />
            <img src={img3} alt='' className='grid-item' />
            <img src={img4} alt='' className='grid-item' />
            <img src={img5} alt='' className='grid-item' />
            <img src={img6} alt='' className='grid-item' />
          </div>
        </div>

        <div className='seo-content__info __inner'>
          <div className='seo-content__info-description'>
            <p className='seo-content__info-description-title'>
              Используйте не только в Online!
            </p>
            <p className='seo-content__info-description-text'>
              Помогите своим клиентам проще делиться отзывами о вашей работе ♥
            </p>
          </div>
          <Link
            className='seo-content__info-link'
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            target='_blank'
          >
            Подключить
          </Link>
        </div>
      </section>

      <section className='seo-description __inner'>
        <p className='seo-description__title'>
          Создание и генерация отзывов на основе нейросети GPT-4
        </p>

        <div className='seo-description__info'>
          <p className='seo-description__info-text'>
            {store.isExpanded ? fullText : previewText}
          </p>
          <button
            className='seo-description__info-button-more'
            onClick={() => store.toggleExpand(!store.isExpanded)}
          >
            {store.isExpanded ? 'Скрыть' : 'Подробнее...'}
          </button>
        </div>
      </section>
    </>
  );
});

export default SeoContent;
