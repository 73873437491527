import { makeAutoObservable, runInAction } from 'mobx';
export class ApiError extends Error {}

const commonEmotions = {
  1: ['😣 Лучше не будет...'],
  2: ['🤔 Оно того не стоило', '😟 В смятении'],
  3: ['😐 Нормально', '🤷‍♂️ Могло быть и хуже', '😶 Есть куда расти'],
  4: ['😊 Оправданы ожидания'],
  5: ['🤩 Очень хорошо!', '❤️ Отличный результат'],
};

const emotionOptions = {
  neutral: {
    1: ['😞 Разочарованы', '😡 Очень злимся!', '😢 Обижены', '🤨 Не доверяем'],
    2: ['😕 Неудовлетворены', '😒 Разочарованы', '🙄 Не вернемся'],
    3: ['🙂 Удовлетворены', '😬 Еще не поняли'],
    4: ['😃 Довольны', '🤝 Доверяем', '👍 Рекомендуем!', '🙏 Благодарим'],
    5: ['😁 Очень довольны', '🙏 Благодарим!', '🤗 Обязательно вернемся'],
  },
  female: {
    1: ['😞 Разочарована', '😡 Очень злюсь!', '😢 Обижена', '🤨 Не доверяю'],
    2: ['😕 Неудовлетворена', '😒 Разочарована', '🙄 Не вернусь'],
    3: ['🙂 Удовлетворена', '😬 Еще не поняла'],
    4: ['😃 Довольна', '🤝 Доверяю', '👍 Рекомендую!', '🙏 Благодарю'],
    5: ['😁 Очень довольна', '🙏 Благодарю!', '🤗 Обязательно вернусь'],
  },
  male: {
    1: ['😞 Разочарован', '😡 Очень злюсь!', '😢 Обижен', '🤨 Не доверяю'],
    2: ['😕 Неудовлетворен', '😒 Разочарован', '🙄 Не вернусь'],
    3: ['🙂 Удовлетворен', '😬 Еще не понял'],
    4: ['😃 Доволен', '🤝 Доверяю', '👍 Рекомендую!', '🙏 Благодарю'],
    5: ['😁 Очень доволен', '🙏 Благодарю!', '🤗 Обязательно вернусь'],
  },
};

const characteristicOptions = {
  1: [
    '🚫 Ненадежно',
    '😡 Отвратительный опыт',
    '👎 Низкое качество',
    '🐢 Медленная работа',
  ],
  2: [
    '⚠️ Надежно (ниже среднего)',
    '😕 Не лучший опыт',
    '🤔 Сомнительное качество',
    '🐌 Недостаточная скорость',
  ],
  3: [
    '🔒 Надежно (средне)',
    '😐 Нормальный опыт',
    '🏅 Среднее качество',
    '🕒 Умеренная скорость',
  ],
  4: [
    '🔒 Надежно',
    '🌟 Приятный опыт',
    '🏆 Высокое качество',
    '🚀 Высокая скорость',
  ],
  5: [
    '🔒 Очень надежно',
    '✨ Замечательный опыт',
    '🏆 Отличное качество',
    '⚡ Быстрая скорость',
  ],
};

class AppStore {
  emotions = [];
  emotionsError = '';

  characteristics = [];
  characteristicsError = '';

  constructor() {
    makeAutoObservable(this);
  }

  product = '';
  productError = '';
  setProduct(product) {
    this.product = product;
    this.productError = '';
  }

  comment = '';
  commentError = '';
  setComment(text) {
    this.comment = text;
    if (text.length < 15 || text.length > 449) {
      this.commentError = true;
    } else {
      this.commentError = false;
    }
  }

  isHumanLike = true;
  toggleHumanLike(value) {
    this.isHumanLike = value;
  }

  stars = 4;
  setStars(count) {
    this.stars = count;
    this.emotions = [];
    this.characteristics = [];
  }

  gender = 'neutral'; // 'neutral', 'male', 'female'
  setGender(gender) {
    this.gender = gender;

    const commonEmotionsForCurrentStars = commonEmotions[this.stars] || [];
    this.emotions = this.emotions.filter((emotion) =>
      commonEmotionsForCurrentStars.includes(emotion)
    );
  }

  toggleEmotion(emotion) {
    if (this.emotions.includes(emotion)) {
      this.emotions = this.emotions.filter((e) => e !== emotion);
    } else if (this.emotions.length < 3) {
      this.emotions.push(emotion);
      this.emotionsError = '';
    }
  }

  toggleCharacteristic(characteristic) {
    if (this.characteristics.includes(characteristic)) {
      this.characteristics = this.characteristics.filter(
        (c) => c !== characteristic
      );
    } else if (this.characteristics.length < 3) {
      this.characteristics.push(characteristic);
      this.characteristicsError = '';
    }
  }

  get emotionOptions() {
    const genderSpecific = emotionOptions[this.gender][this.stars] || [];
    const common = commonEmotions[this.stars] || [];
    return [...genderSpecific, ...common];
  }

  get characteristicOptions() {
    return characteristicOptions[this.stars] || [];
  }

  marketplace = 'yandex'; // 'yandex' or 'avito'
  setMarketplace(marketplace) {
    this.marketplace = marketplace;
  }

  randomFill() {
    const currentProduct = this.product;

    const genders = ['neutral', 'male', 'female'];
    this.setGender(genders[Math.floor(Math.random() * genders.length)]);
    this.setStars(Math.floor(Math.random() * 5) + 1);

    this.emotions = [];
    const availableEmotions = this.emotionOptions;
    for (let i = 0; i < 3 && i < availableEmotions.length; i++) {
      const randomEmotion =
        availableEmotions[Math.floor(Math.random() * availableEmotions.length)];
      if (!this.emotions.includes(randomEmotion)) {
        this.emotions.push(randomEmotion);
      }
    }

    this.characteristics = [];
    const availableCharacteristics = this.characteristicOptions;
    for (let i = 0; i < 3 && i < availableCharacteristics.length; i++) {
      const randomCharacteristic =
        availableCharacteristics[
          Math.floor(Math.random() * availableCharacteristics.length)
        ];
      if (!this.characteristics.includes(randomCharacteristic)) {
        this.characteristics.push(randomCharacteristic);
      }
    }

    this.toggleHumanLike(Math.random() < 0.5);
    this.setProduct(currentProduct);

    if (this.product.trim() === '') {
      this.productError = 'Заполните поле (максимум 150 символов)';
    }
  }

  get serverData() {
    const genderInCyrillic = {
      neutral: 'не определено',
      male: 'мужчина',
      female: 'женщина',
    };

    const review = {
      title: this.product,
      attentions: this.comment,
      gender: genderInCyrillic[this.gender],
      stars: this.stars,
      emotions: this.emotions,
      traits: this.characteristics,
      simplified: this.isHumanLike,
    };

    return review;
  }

  validateFields() {
    if (!this.emotions.length || this.emotions.length < 2) {
      this.emotionsError = 'Добавьте минимум 2 эмоции';
      return false;
    }

    if (!this.characteristics.length || this.characteristics.length < 2) {
      this.characteristicsError = 'Добавьте минимум 2 характеристики';
      return false;
    }

    if (this.product.trim() === '') {
      this.productError = 'Заполните поле (максимум 150 символов)';
      return false;
    }

    if (this.comment.trim() === '' || this.comment.length < 15 || this.comment.length > 449) {
      this.commentError = 'Заполните поле (от 15 до 450 символов)';
      return false;
    }

    return true;
  }

  reviewResult = '';
  reviewResultError = '';
  async generateReview() {
    const url = process.env.REACT_APP_BACKEND_ADDRESS;
    const data = this.serverData;
    let body;

    try {
      const response = await fetch(`${url}/ai`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new ApiError(body.message);

      const result = await response.json();
      runInAction(() => {
        this.reviewResult = result.response || '';
        this.reviewResultError = !result.response ? result.message || 'Не удалось сгенерировать отзыв' : '';
      });
    } catch (e) {
      runInAction(() => {
        this.reviewResult = '';
        this.reviewResultError = e instanceof ApiError ? e.message : 'Произошла неизвестная ошибка';
      });
    }
  }

  get publishUrl() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return this.marketplace === 'avito'
      ? `${baseUrl}/reviews-on-avito` 
      : `${baseUrl}/reviews-on-yandexmaps`;
  }

  // seo block
  isExpanded = false;
  toggleExpand(value) {
    this.isExpanded = value;
  }

  // popup
  redirectUrl = null;
  setRedirectUrl(url) {
    this.redirectUrl = url;
  }

  clearRedirectUrl() {
    this.redirectUrl = null;
  }

  isOpen = false;
  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
    this.clearRedirectUrl();
  }

  handleRedirect(url) {
    this.setRedirectUrl(url);
    this.open();
  }
}

export const appStore = new AppStore();
