import { observer } from 'mobx-react';

import Faqed from 'components/UI/Faqed/Faqed';
import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import { getCountActions, getCountDays, getCountReviews } from 'utils/hooks/helpers';

import './ServiceCard.scss';

const ServiceCard = observer(({ 
  logoClass,
  iconClass,
  reviewCount, 
  pf, 
  deadline, 
  actionCount, 
  price,
  onOrder,
  iconButton
}) => {
  return (
    <div className='services-card'>
      <div className={`services-card__top ${logoClass}`}>
        <div className={`services-card__top-logo ${logoClass}`} />
        <div className={`services-card__top-icon ${iconClass}`} />
      </div>

      <div className='services-card__container'>
        <div className='services-card__info'>
          <div className='services-card__info-parameters'>
            <p>{reviewCount} {getCountReviews(reviewCount)}</p>
            <Faqed text='Поведенческие факторы'>+ {pf} ПФ</Faqed>
          </div>

          <div className='services-card__info-description'>
            <div className='services-card__info-description-container'>
              <div className='services-card__info-description-icon search' />
              <p className='services-card__info-description-text'>Поиск исполнителей <span className='services-card__info-description-span'>~{deadline} {getCountDays(deadline)}</span></p>
            </div>
            <div className='services-card__info-description-container'>
              <div className='services-card__info-description-icon action' />
              <p className='services-card__info-description-text'>Всего <span className='services-card__info-description-span'>{actionCount}</span> {getCountActions(actionCount)}</p>
            </div>
          </div>
        </div>

        <div className='services-card__bottom'>
          <p>{`${price}\u00a0₽`}</p>
          <div className='services-card__bottom-buttons'>
            <button className='services-card__order-button' onClick={onOrder}>Заказать</button>
            <ButtonWithIcon
              title=''
              className='services-card__settings-button'
              icon={iconButton}
              onClick={onOrder}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ServiceCard;
