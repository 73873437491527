import './Header.scss';

const Header = () => {
  return (
    <header className='header'>
      <div className='header__container'>
        <h1 className='header__title'>
          GPT <span>— напишет отзыв за тебя</span>
        </h1>
        <div className='header__logo' />
      </div>
    </header>
  );
};

export default Header;
