import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import support from 'assets/images/support.svg';
import repeat from 'assets/images/repeat-button.svg';

import './ErrorContent.scss';

const ErrorContent = ({ repeatRequest, errorMessage }) => {
  const formattedText = errorMessage
    .split('\n\n')
    .map((paragraph, index) => (
      <p key={index} className='error-content__header-text'>
        {paragraph}
      </p>
    ));

  return (
    <div className='error-content'>
      <div className='error-content__header'>
        <div className='error-content__header-icon' />
        <p className='error-content__header-title'>Что-то пошло не так</p>
        {formattedText}
      </div>

      <div className='error-content__buttons'>
        <ButtonWithIcon
          title='Повторить попытку'
          icon={repeat}
          onClick={repeatRequest}
          ariaLabel='Повторить генерацию отзыва'
        />
        <ButtonWithIcon
          title='Написать в поддержку'
          icon={support}
          to={process.env.REACT_APP_SUPPORT_ADDRESS}
          ariaLabel='Повторить генерацию отзыва'
        />
      </div>
    </div>
  );
};

export default ErrorContent;
