import { observer } from 'mobx-react';

import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import './OptionSelector.scss';

const OptionSelector = observer(
  ({ title, options, selected, onToggle, maxSelect, error, selectorRef }) => {

    const getCountStyle = () => {
      if (selected.length === 1) {
        return { color: '#EA4335' };
      } else if (selected.length >= 2) {
        return { color: '#57c058' };
      }
      return { color: '#9F9F9F' };
    };

    const getMaxSelectStyle = () => {
      if (selected.length >= 2) {
        return { color: '#57c058' };
      }
      return { color: '#9F9F9F' };
    };

    return (
      <div className={`option-selector ${error ? 'error' : ''}`} ref={selectorRef}>
        {error && <p className='option-selector__error'>{error}</p>}
        <div className='option-selector__description'>
          <p className='option-selector__description-title'>{title}</p>
          <div className='option-selector__description-line' />
          <div>
            <span style={getCountStyle()}>{selected.length}</span>
            <span style={getMaxSelectStyle()}>/{maxSelect}</span>
          </div>
          
        </div>
        <div className='option-selector__buttons'>
          {options.map((option) => (
            <ButtonWithIcon
              key={option}
              title={option}
              className={`option-selector__button ${selected.includes(option) ? 'selected' : ''}`}
              onClick={() => onToggle(option)}
              ariaLabel='Выбор характеристик'
            />
          ))}
        </div>
      </div>
    );
  }
);

export default OptionSelector;
