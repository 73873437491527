import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import yandex from 'assets/images/yandexmap.svg';
import avito from 'assets/images/messageBlack.svg';

import './MarketsSelector.scss';

const MarketsSelector = observer(({ store }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAvito = location.pathname.includes('ai-otzyv-obyavleniya');

  const handleMarketplaceChange = (marketplace) => {
    store.setMarketplace(marketplace);
    if (marketplace === 'yandex') {
      navigate('/');
    } else if (marketplace === 'avito') {
      navigate('/ai-otzyv-obyavleniya');
    }
  };

  return (
    <div className='market-selector'>
      <p className='market-selector__title'>Куда пишем отзыв?</p>
      <div className='market-selector__buttons'>
        <ButtonWithIcon
          title='Яндекс Карты'
          className={`market-selector__button-yandex ${!isAvito ? 'active' : ''}`}
          icon={yandex}
          onClick={() => handleMarketplaceChange('yandex')}
          ariaLabel='Выбрать площадку для публикации - Яндекс.Карты'
        />
        <ButtonWithIcon
          title='Объявления'
          className={`market-selector__button-avito ${isAvito ? 'active' : ''}`}
          icon={avito}
          onClick={() => handleMarketplaceChange('avito')}
          ariaLabel='Выбрать площадку для публикации - объявления'
        />
      </div>
    </div>
  );
});

export default MarketsSelector;
