import { observer } from 'mobx-react';

import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import neutral from 'assets/images/neutral.svg';
import man from 'assets/images/man.svg';
import woman from 'assets/images/woman.svg';

import './GenderSelector.scss';

const GenderSelector = observer(({ store }) => {
  return (
    <div className='gender-selector'>
      <ButtonWithIcon
        title='Без пола'
        className={store.gender === 'neutral' ? 'active' : ''}
        icon={neutral}
        onClick={() => store.setGender('neutral')}
        ariaLabel='Выбрать пол - Без пола'
      />
      <ButtonWithIcon
        title='Мужчина'
        className={store.gender === 'male' ? 'active' : ''}
        icon={man}
        onClick={() => store.setGender('male')}
        ariaLabel='Выбрать пол - Мужчина'
      />
      <ButtonWithIcon
        title='Женщина'
        className={store.gender === 'female' ? 'active' : ''}
        icon={woman}
        onClick={() => store.setGender('female')}
        ariaLabel='Выбрать пол - Женщина'
      />
    </div>
  );
});

export default GenderSelector;
