import { observer } from 'mobx-react';

import Toggler from 'components/UI/Toggler/Toggler';

import robot from 'assets/images/robot.png';

import './ResultHeader.scss';

const ResultHeader = observer(({ store, isMobile }) => {
  return (
    <div className='result-header'>
      <div className='result-header__container-write-human'>
        <Toggler
          isActive={store.isHumanLike}
          handler={() => store.toggleHumanLike(!store.isHumanLike)}
        />
        <div className='result-header__container-write-human-title'>
          <p>Пиши, как человек</p>
          <span>
            С ошибками и опечатками — совсем как {!isMobile && <br />} настоящие пользователи
          </span>
        </div>
        {!isMobile && (
          <div className='result-header__container-write-human-subtitle'>
            Поможет модерации отзыва
          </div>
        )}
      </div>
      <img src={robot} alt='' className='result-header__robots-icon' />
    </div>
  );
});

export default ResultHeader;
