import Lottie from 'lottie-react';

import './LottieAnimation.scss';

const LottieAnimation = ({ animationData }) => {
  const options = {
    animationData: animationData,
    autoplay: true,
  };

  return (
    <div className='lottie-animation'>
      <Lottie {...options} />
    </div>
  );
};

export default LottieAnimation;
