import './Toggler.scss';

const Toggler = ({ isActive, handler }) => {
  return (
    <button
      className={'toggler ' + (isActive ? 'active' : '')}
      onClick={handler}
    >
      <div className='toggler__thumb' />
    </button>
  );
};

export default Toggler;
